import React from "react"
import { MainVisualImg } from "./index/MainVisualImg"

function IngCover() {
  return (
    <div className="ingCover">
      <p className="imgWrap">
        <MainVisualImg />
      </p>
      <p className="line1">THIS PAGE</p>
      <p className="line2">UNDER CONSTRUCTION</p>
      <p className="line3">
        현재 페이지는 준비 중 입니다. 다음에 다시 방문해 주세요. 감사합니다.
      </p>
      <p className="line4">
        The current page is being prepared. Please visit us again next time.
        thank you.
      </p>
    </div>
  )
}

export default IngCover
