import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"
import IngCover from "../../components/ingCover"

const NationStand = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div style={{ minHeight: "100vh" }}>
      <IngCover />
    </div>
  </Layout>
)

export default NationStand
