import React from "react"
import { useSpring, animated } from "react-spring"

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans4 = (x, y) => `translate3d(${x / 6 + 40}px,${y / 6}px,0)` //front
const trans3 = (x, y) => `translate3d(${x / 7 + 30}px,${y / 7}px,0)`
const trans2 = (x, y) => `translate3d(${x / 8 + 20}px,${y / 8}px,0)`
const trans1 = (x, y) => `translate3d(${x / 9 + 10}px,${y / 9}px,0)`
const trans0 = (x, y) => `translate3d(${x / 10 - 20}px,${y / 10 + 30}px,0)` //back

export function MainVisualImg() {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))
  return (
    <div
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      className="visualImgContainer"
      role="button"
      tabIndex={0}
    >
      <animated.div
        className="card0"
        style={{ transform: props.xy.interpolate(trans0) }}
      />
      <animated.div
        className="card1"
        style={{ transform: props.xy.interpolate(trans1) }}
      />
      <animated.div
        className="card2"
        style={{ transform: props.xy.interpolate(trans2) }}
      />
      <animated.div
        className="card3"
        style={{ transform: props.xy.interpolate(trans3) }}
      />
      <animated.div
        className="card4"
        style={{ transform: props.xy.interpolate(trans4) }}
      />
    </div>
  )
}
